<template>
  <div class="main-div c-enrichment">
    <div v-if="showProgressLoader" class="top-animations-process">
      <div class="process-box">
        <div class="loader-process"></div>

        <div class="process-heading">
          <div class="main-loading-svg">
            <svg width="111" height="33" viewBox="0 0 111 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_4031_10544)">
                <path
                  d="M13.1536 32.4136H97.8464C101.335 32.4136 104.681 31.0278 107.147 28.561C109.614 26.0942 111 22.7486 111 19.26V0H13.1536C9.66502 0 6.31936 1.38582 3.85259 3.85259C1.38582 6.31936 0 9.66502 0 13.1536V19.26C0 22.7486 1.38582 26.0942 3.85259 28.561C6.31936 31.0278 9.66502 32.4136 13.1536 32.4136Z"
                  fill="#2589FF" class="svg-elem-1"></path>

                <path
                  d="M22.3604 8.35938V21.3552H19.9438L19.7739 20.0904C18.968 21.1828 17.6753 21.5603 16.4021 21.5603C13.3276 21.5603 11.0166 19.7748 11.0166 16.7635C11.0166 13.6077 13.2851 11.9655 16.339 11.9655C17.4411 11.9655 19.1585 12.4862 19.7739 13.4353V8.35938H22.3604ZM13.6031 16.7635C13.6031 18.362 14.9189 19.4593 16.572 19.4593C18.2045 19.4593 19.6064 18.4178 19.6064 16.7635C19.6064 15.1637 18.2069 14.0859 16.572 14.0859C14.9189 14.0859 13.6031 15.1079 13.6031 16.7635Z"
                  fill="white" class="svg-elem-2"></path>

                <path
                  d="M32.8988 12.1886H35.3822V21.3549H32.9413L32.8138 20.0198C32.2203 21.0988 30.5878 21.6195 29.4214 21.6378C26.325 21.656 24.0347 19.9834 24.0347 16.7669C24.0347 13.6111 26.4306 11.9507 29.4845 11.9689C30.8839 11.9689 32.2203 12.5454 32.8138 13.457L32.8988 12.1886ZM26.6224 16.7633C26.6224 18.5099 28 19.5549 29.7175 19.5549C33.7897 19.5549 33.7897 13.9959 29.7175 13.9959C28 13.9922 26.6224 15.0154 26.6224 16.7633Z"
                  fill="white" class="svg-elem-3"></path>

                <path
                  d="M40.7653 9.60547V12.2138H43.6492V14.1559H40.7447V18.1164C40.7447 18.9903 41.2958 19.4175 42.1017 19.4175C42.5331 19.4074 42.9583 19.3125 43.3531 19.1384L44.0741 21.0743C43.3889 21.3251 42.6662 21.458 41.9366 21.4676C39.6681 21.5416 38.1837 20.408 38.1837 18.1212V14.1559H36.2319V12.2138H38.174V9.84701L40.7653 9.60547Z"
                  fill="white" class="svg-elem-4"></path>

                <path
                  d="M53.8693 12.1886H56.3502V21.3549H53.9117L53.7904 20.0198C53.1956 21.0988 51.5631 21.6195 50.3918 21.6378C47.2967 21.656 45.0063 19.9834 45.0063 16.7669C45.0063 13.6111 47.4023 11.9507 50.4562 11.9689C51.8556 11.9689 53.1908 12.5454 53.7855 13.457L53.8693 12.1886ZM47.5929 16.7633C47.5929 18.5099 48.9717 19.5549 50.6892 19.5549C54.7602 19.5549 54.7602 13.9959 50.6892 13.9959C48.9766 13.9922 47.5929 15.0154 47.5929 16.7633Z"
                  fill="white" class="svg-elem-5"></path>

                <path
                  d="M66.7411 11.2617L68.6491 12.5265L67.4827 13.8276C68.2886 14.6275 68.5848 15.539 68.5848 16.5428C68.5848 17.6765 68.0993 19.275 66.3794 19.9632C68.1187 20.7254 68.5423 21.8227 68.5423 22.9976C68.5423 25.5271 66.3369 27.088 63.3049 27.088C60.2729 27.088 58.0044 25.4713 58.0044 22.9976H60.5691C60.5691 24.1883 61.8205 24.9688 63.3049 24.9688C64.7893 24.9688 65.9558 24.2624 65.9558 22.9976C65.9558 21.7329 64.5988 21.1575 63.3049 21.1575C60.0399 21.1575 58.0044 19.4097 58.0044 16.5452C58.0044 13.6808 60.3785 11.8965 63.3049 11.8965C64.1327 11.8965 64.9799 11.99 65.7009 12.4172L66.7411 11.2617ZM60.5691 16.5428C60.5691 18.1413 61.7998 19.0917 63.3049 19.0917C64.7893 19.0917 66.0201 18.1207 66.0201 16.5428C66.0201 14.9649 64.7893 13.9587 63.3049 13.9587C61.7998 13.9587 60.5691 14.9431 60.5691 16.5428Z"
                  fill="white" class="svg-elem-6"></path>

                <path
                  d="M77.1939 21.3573V16.4488C77.1939 15.2411 76.4729 14.1427 75.0516 14.1427C73.6521 14.1427 72.8255 15.2351 72.8255 16.4488V21.3573H70.2378V12.1727H72.635L72.8255 13.2882C73.3766 12.3584 74.5855 12.0234 75.582 12.0234C76.8334 12.0234 78.0836 12.4701 78.6783 13.7348C79.6105 12.4325 80.8194 12.0611 82.1764 12.0611C85.1453 12.0611 86.6091 13.6596 86.6091 16.4124V21.3573H84.0213V16.4124C84.0213 15.1986 83.4484 14.1803 82.0489 14.1803C80.6495 14.1803 79.7804 15.2411 79.7804 16.4488V21.3573H77.1939Z"
                  fill="white" class="svg-elem-7"></path>

                <path
                  d="M97.5275 12.1883H100.008V21.3547H97.5712L97.4498 20.0195C96.855 21.0986 95.2225 21.6193 94.0513 21.6375C90.9549 21.6521 88.667 19.9807 88.667 16.763C88.667 13.6072 91.063 11.9468 94.1168 11.965C95.5163 11.965 96.8514 12.5415 97.4462 13.4531L97.5275 12.1883ZM91.2523 16.763C91.2523 18.5096 92.6299 19.5547 94.3486 19.5547C98.4196 19.5547 98.4196 13.9956 94.3486 13.9956C92.6299 13.992 91.2523 15.0152 91.2523 16.763Z"
                  fill="white" class="svg-elem-8"></path>
              </g>

              <defs>
                <clipPath id="clip0_4031_10544">
                  <rect width="111" height="32.4136" fill="white" class="svg-elem-9"></rect>
                </clipPath>
              </defs>
            </svg>
          </div>

          <div class="main-loading-box">
            <h2>Retrieving the data from a source</h2>

            <h2>Looking for specific terms or phrases within the data</h2>

            <h2>Analyzing and manipulating the data for further use</h2>

            <h2>Retrieving the data from a source</h2>

            <h2>Looking for specific terms or phrases within the data</h2>

            <h2>Analyzing and manipulating the data for further use</h2>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="main-data-wrapper">
      <div class="top animate">
        <div class="contact-image">
          <!-- <img src="../../../../assets/images/data-processing-icon.svg" alt="" /> -->

          <p>Contact Enrichment</p>
        </div>

        <div class="action-btn ">
          <div v-if="!currentlyApiLoading" class="refresh-btn">
            <button class="secondary-btn-outline" @click="handleRouteChange">
              Back
            </button>
          </div>

          <div class="single-card-box" style=" text-align: center;" v-if="!currentlyApiLoading">
            <div v-if="enrichmentData.status == 'Completed' ||
      enrichmentData.status == 'Error'
      ">
              <button class="secondary-btn-outline btn" @click="hanldeCopyToNewModal(enrichmentData.status)">
                <span>
                  Copy To New
                </span>
              </button>
            </div>

            <div v-if="enrichmentData.status !== 'Completed' &&
      enrichmentData.status !== 'Error'
      ">
              <button class="secondary-btn-outline btn" v-bind:class="{
      InRed: enrichmentData.status == 'In Progress',

      InGreen: enrichmentData.status == 'Paused',
    }" @click="modelOpen(enrichmentData)">
                <span>
                  {{
      enrichmentData.status == "In Progress"
        ? "Pause"
        : enrichmentData.status == "Paused"
          ? "Start"
          : "Completed"
    }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="middle-detail-card dashbord-enrichment ">
        <b-card>
          <div class="main-details-card" v-if="!currentlyApiLoading || Object.keys(enrichmentData).length !== 0
      ">
            <div class="rightchart ">
              <div>
                <div v-if="hasCounts">
                  <apexchart width="900" class="chart-donut" type="donut" :options="chartOptions" :series="[
      to_process_contacts_count,

      success_contacts_count,

      api_error_count,

      // gmail_contacts_count,

      contacts_without_email_count,

      contacts_without_properties_count,
    ]"></apexchart>
                </div>

                <div v-else>
                  <div class="no-data-box text-center">
                    <img src="../../../../assets/images/icons/no-data.svg" alt="" />

                    <h5 class="mt-4">
                      No contact here to<br />
                      show the graph
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div class="leftcards">
              <div class="upper-setting-box">
                <div class="inner-single-setting d-flex align-items-center">
                  <h6>Start Date:</h6>

                  <span>
                    {{
      enrichmentData.created_at
        ? getDateInformat(enrichmentData.created_at)
        : ""
    }}
                  </span>
                </div>

                <div class="inner-single-setting d-flex align-items-center">
                  <h6>Start Time:</h6>

                  <span>
                    {{
        enrichmentData.created_at
          ? getformatDate(enrichmentData.created_at)
          : ""
      }}
                  </span>
                </div>

                <div class="inner-single-setting d-flex align-items-center">
                  <h6>Credit Used:</h6>

                  <span>
                    <p>
                      <span v-if="enrichmentData.creditUsed !== '' &&
      enrichmentData.creditUsed !== null
      ">
                        {{ enrichmentData.creditUsed }}
                      </span>

                      <span v-else>0</span>
                    </p>
                  </span>
                </div>

                <div class="inner-single-setting d-flex align-items-center">
                  <h6>Status</h6>

                  <p v-if="enrichmentData.status === 'Completed'" class="status-td completed ">
                    {{ enrichmentData.status }}
                  </p>

                  <p v-else-if="enrichmentData.status === 'Paused'" class="status-td duplicate paused">
                    {{ enrichmentData.status }}
                  </p>

                  <p v-else-if="enrichmentData.status === 'In Progress'" class="status-td in-progress-patch completed">
                    {{ enrichmentData.status }}
                  </p>

                  <p v-else-if="enrichmentData.status === 'Error'" class="status-td duplicate">
                    {{ enrichmentData.status }}
                  </p>

                  <p v-else class="status-td duplicate">
                    {{ enrichmentData.status }}
                  </p>
                </div>

                <div class="inner-single-setting d-flex align-items-center">
                  <h6>Remaining Time:</h6>

                  <span>
                    {{ remainingTime }}
                  </span>
                </div>
              </div>

              <div class="main-left-card">
                <div class="error-message-main">
                  <div v-if="enrichmentData.error_message !== '' &&
      enrichmentData.error_message !== null
      " class="single-card-box">
                    <h4>Error Messages</h4>

                    <p class="status-td duplicate">
                      {{ enrichmentData.error_message }}
                    </p>
                  </div>
                </div>

                <div class="middle-setting-box">
                  <div class="single-card-box" v-if="enrichmentData.enrichmentName">
                    <h4>Enrichment Name</h4>

                    <p>
                      <span>
                        {{ enrichmentData.enrichmentName }}
                      </span>
                    </p>
                  </div>

                  <div class="single-card-box" v-if="enrichmentData.listType">
                    <h4>List Type</h4>

                    <p>
                      <span>
                        {{ enrichmentData.listType }}
                      </span>
                    </p>
                  </div>

                  <div class="single-card-box" v-if="enrichmentData.enrich_all_contacts === 1">
                    <h4>Enrich All Contacts</h4>

                    <p class="R1">
                      <span v-if="enrichmentData.enrich_all_contacts === 1">
                        <img src="../../../../assets/images/true-item-icon.svg" alt="" />
                      </span>

                      <span v-if="enrichmentData.enrich_all_contacts === 0 ||
      enrichmentData.enrich_all_contacts === null
      ">
                        <img src="../../../../assets/images/cross-item-icon.svg" alt="" />
                      </span>
                    </p>
                  </div>

                  <div class="single-card-box" v-if="enrichmentData.enrich_all_contacts === 0 ||
      enrichmentData.enrich_all_contacts === null
      ">
                    <h4>Selected List(s)</h4>

                    <p class="">
                      <span>
                        <p>
                          {{
      enrichmentData.selectedListJsonString
        ? getListName(
          enrichmentData.selectedListJsonString
        )
        : "-"
    }}
                        </p>
                      </span>
                    </p>
                  </div>

                  <div class="single-card-box" v-if="enrichmentData.already_enriched === 1">
                    <h4>Enrich already enriched Contacts?</h4>

                    <p class="R1">
                      <span v-if="enrichmentData.already_enriched === 1">
                        <img src="../../../../assets/images/true-item-icon.svg" alt="" />
                      </span>

                      <span v-if="enrichmentData.already_enriched === 0 ||
      enrichmentData.already_enriched === null
      ">
                        <img src="../../../../assets/images/cross-item-icon.svg" alt="" />
                      </span>
                    </p>
                  </div>

                  <div class="single-card-box">
                    <h4>Company Technology</h4>

                    <p class="R1">
                      <span v-if="enrichmentData.deep_traffic === 1">
                        <img src="../../../../assets/images/true-item-icon.svg" alt="" />
                      </span>

                      <span v-if="enrichmentData.deep_traffic === 0 ||
      enrichmentData.deep_traffic === null
      ">
                        <img src="../../../../assets/images/cross-item-icon.svg" alt="" />
                      </span>
                    </p>
                  </div>

                  <div class="single-card-box">
                    <h4>Company Basic</h4>

                    <p class="R1">
                      <span v-if="enrichmentData.company_premium === 1">
                        <img src="../../../../assets/images/true-item-icon.svg" alt="" />
                      </span>

                      <span v-if="enrichmentData.company_premium === 0 ||
      enrichmentData.company_premium === null
      ">
                        <img src="../../../../assets/images/cross-item-icon.svg" alt="" />
                      </span>
                    </p>
                  </div>

                  <div class="single-card-box">
                    <h4>Company French</h4>

                    <p class="R1">
                      <span v-if="enrichmentData.company_french === 1">
                        <img src="../../../../assets/images/true-item-icon.svg" alt="" />
                      </span>

                      <span v-if="enrichmentData.company_french === 0 ||
      enrichmentData.company_french === null
      ">
                        <img src="../../../../assets/images/cross-item-icon.svg" alt="" />
                      </span>
                    </p>
                  </div>

                  <div class="single-card-box">
                    <h4>Company Full</h4>

                    <p class="R1">
                      <span v-if="enrichmentData.company_full === 1">
                        <img src="../../../../assets/images/true-item-icon.svg" alt="" />
                      </span>

                      <span v-if="enrichmentData.company_full === 0 ||
      enrichmentData.company_full === null
      ">
                        <img src="../../../../assets/images/cross-item-icon.svg" alt="" />
                      </span>
                    </p>
                  </div>

                  <div class="single-card-box">
                    <h4>Create and associate a new company?</h4>

                    <p class="R1">
                      <span v-if="enrichmentData.associate_company === 1">
                        <img src="../../../../assets/images/true-item-icon.svg" alt="" />
                      </span>

                      <span v-if="enrichmentData.associate_company === 0 ||
      enrichmentData.associate_company === null
      ">
                        <img src="../../../../assets/images/cross-item-icon.svg" alt="" />
                      </span>
                    </p>
                  </div>

                  <div class="single-card-box">
                    <h4>Create Task?</h4>

                    <p class="R1">
                      <span v-if="enrichmentData.create_task === 1">
                        <img src="../../../../assets/images/true-item-icon.svg" alt="" />
                      </span>

                      <span v-if="enrichmentData.create_task === 0 ||
      enrichmentData.create_task === null
      ">
                        <img src="../../../../assets/images/cross-item-icon.svg" alt="" />
                      </span>
                    </p>
                  </div>
                </div>

                <div v-if="enrichmentData.create_task == 1" class="bottom-settings-box">
                  <div class="single-card-box">
                    <h4>Task Subject</h4>

                    <p>
                      {{ enrichmentData.task_subject }}
                    </p>
                  </div>

                  <div class="single-card-box body-task-text">
                    <h4>Task Body</h4>

                    <p>
                      {{ enrichmentData.task_body }}
                    </p>
                  </div>

                  <div class="single-card-box">
                    <h4>Task Priority</h4>

                    <p>
                      {{ enrichmentData.task_priority }}
                    </p>
                  </div>

                  <div class="single-card-box">
                    <h4>Task Type</h4>

                    <p>
                      {{ enrichmentData.task_type }}
                    </p>
                  </div>

                  <div class="single-card-box">
                    <h4>Assign to contact’s owner?</h4>

                    <p class="R1">
                      <span v-if="enrichmentData.taskAssignToOwner === 1">
                        <img src="../../../../assets/images/true-item-icon.svg" alt="" />
                      </span>

                      <span v-if="enrichmentData.taskAssignToOwner === 0 ||
      enrichmentData.taskAssignToOwner === null
      ">
                        <img src="../../../../assets/images/cross-item-icon.svg" alt="" />
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <modal name="my-modal" class="modaldata">
              <div class="enrich-head">
                <img aria-hidden="true" @click="closeModal" src="../../../../assets/images/popup-close.png" />
              </div>

              <div class="enrichmodal newenrichmodal" style=" text-align: center; ">
                <h4 style="text-align: center;  ">
                  <span class="text-color fw-bolder">Are you sure,</span>
                </h4>

                <p class="modal-subheading" style="text-align: center;">
                  You want to {{ this.actionText }}
                </p>

                <div class="action_button" style="text-align: center;">
                  <button @click="enrichAction" class="action_perform secondary-btn-outline">
                    Yes
                  </button>

                  <button @click="closeModal" class="action_perform secondary-btn-outline">
                    No
                  </button>
                </div>
              </div>
            </modal>
          </div>

          <div class="main-loader-card" v-else style="padding-bottom: 25px;">
            <div class="rightchart-main" style="margin-top: 40px;">
              <div class="rightchart-main skeletonbox">
                <div class="skeleton-loder skeleton-table-h"></div>

                <div class="skeleton-loder skeleton-table-h roundbox"></div>
              </div>
            </div>

            <div class="leftcards" style="margin-top: 40px;">
              <div>
                <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                <!-- <div
    
                    class="skeleton-loder skeleton-table-h"
    
                    style="height: 20px;"
    
                  >
    
                </div> -->

                <div class="skeleton-loder skeleton-table-h" style="height: 20px;"></div>

                <div class="skeleton-loder skeleton-table-h" style="height: 20px;"></div>

                <div class="skeleton-loder skeleton-table-h" style="height: 20px;"></div>

                <div class="skeleton-loder skeleton-table-h" style="height: 20px;"></div>

                <div class="skeleton-loder skeleton-table-h" style="height: 20px;"></div>

                <div class="skeleton-loder skeleton-table-h" style="height: 20px;"></div>
              </div>
            </div>
          </div>
        </b-card>
      </div>

      <b-card no-body>
        <div class="jobSwitchClass">
          <div class="search-container ">
            <!-- <input type="text" placeholder="Search Contact... " @change="getSearchcontact((e)=>{e.target.value})"    /> -->

            <div class="searchicon">
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="11" cy="11" r="8" stroke="#828282" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />

                <path d="M16.5 16.9579L21.5 21.958" stroke="#828282" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>

            <input type="text" class="searchbar" placeholder="Search by Email ID" @input="getSearchcontact()"
              v-model="searchEmail" />

            <span class="close-icon" v-if="searchEmail" @click="clearSearch()">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1945_13556)">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M9.5 0C4.2533 0 0 4.2533 0 9.5C0 14.7467 4.2533 19 9.5 19C14.7467 19 19 14.7467 19 9.5C19 4.2533 14.7467 0 9.5 0ZM7.40049 6.14124C7.05268 5.79343 6.48877 5.79343 6.14095 6.14124C5.79314 6.48906 5.79314 7.05297 6.14095 7.40078L8.24018 9.5L6.14095 11.5992C5.79314 11.947 5.79314 12.5109 6.14095 12.8588C6.48877 13.2066 7.05268 13.2066 7.40049 12.8588L9.49971 10.7595L11.5989 12.8588C11.9467 13.2066 12.5107 13.2066 12.8585 12.8588C13.2063 12.5109 13.2063 11.947 12.8585 11.5992L10.7592 9.5L12.8585 7.40078C13.2063 7.05297 13.2063 6.48906 12.8585 6.14124C12.5107 5.79343 11.9467 5.79343 11.5989 6.14124L9.49971 8.24047L7.40049 6.14124Z"
                    fill="#7A869A" />
                </g>

                <defs>
                  <clipPath id="clip0_1945_13556">
                    <rect width="19" height="19" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
          </div>
        </div>

        <b-tabs pills card>
          <!-- Tab In progress -->

          <b-tab :title="'In Progress (' + this.to_process_contacts_count + ')'"
            @click="handleTabClick('to_process_contacts', 1)">
            <div>
              <div class="start-table text-center mb-5">
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>
                </div>

                <div v-else>
                  <div v-if="to_process_contacts.length == 0" class="start-table text-center py-4">
                    <p>No contact found</p>
                  </div>

                  <table v-if="!currentlyApiLoading && to_process_contacts.length != 0
      " class="first-tables in-progress-table table-new">
                    <thead>
                      <tr>
                        <th>
                          Contact Id

                          <img src="../../../../assets/images/arrow-down.png" alt="" />
                        </th>

                        <th>Status</th>

                        <th>Email</th>

                        <th>Curl Link</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(data, index) in to_process_contacts" :key="index">
                        <td>
                          <a v-bind:href="generateLink(
      data.contact_id,

      enrichmentData.portal_id
    )
      " target="_blank">{{ data.contact_id }}</a>
                        </td>

                        <td class="status-td in_progress">
                          <span>In Progress</span>
                        </td>

                        <td v-html="highlightSearchText(data.contact_email)"></td>

                        <td class="text-left">
                          <span class="table-text" v-if="data.curl ==
      'The cURL request is not initiated because the contact does not contain an email address.' ||
      data.curl ==
      'No curl request is initiated when the contact information includes a Gmail address.' ||
      data.curl ==
      'The curl request is not being made because the contact does not contain the required properties' ||
      data.curl === ''
      ">{{
      data.curl || "No curl request generated yet"
    }}</span>

                          <button v-else class="databtn" @click="copyCurl(data.curl)">
                            <img src="../../../../assets/images/copy-item-icon.svg" alt="" />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div v-if="!currentlyApiLoading" class="pagination ">
                <div class="main-page">
                  <p>
                    Displaying

                    <strong> {{ this.to_process_contacts.length }} </strong> out
                    of

                    <strong> {{ this.to_process_contacts_count }} </strong>
                  </p>

                  <div>
                    <strong class="mr-2">{{ currentPage }} -

                      {{ to_process_contacts_pages }}</strong>

                    <button :class="currentPage === 1
        ? 'disableContactEnrich previous-btn'
        : 'activeContactEnrich previous-btn'
      " @click="pageChange(currentPage - 1)" :disabled="currentPage === 1">
                      <img src="../../../../assets/images/arrow-left-icon.png" alt="" />
                    </button>

                    <button :class="to_process_contacts_pages === currentPage
        ? 'disableContactEnrich next-btn'
        : 'activeContactEnrich next-btn'
      " @click="pageChange(currentPage + 1)" :disabled="this.to_process_contacts_pages === this.currentPage
      ">
                      <img src="../../../../assets/images/right-icon.png" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>

          <!-- Tab Success -->

          <b-tab :title="'Success (' + this.success_contacts_count + ')'" @click="handleTabClick('success', 1)">
            <div>
              <div class="start-table text-center mb-5">
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>
                </div>

                <div v-else>
                  <div v-if="success_contacts.length == 0" class="start-table text-center py-4">
                    <p>No contact found</p>
                  </div>

                  <table v-if="!currentlyApiLoading && success_contacts.length != 0" class="first-tables table-new">
                    <thead>
                      <tr>
                        <th>
                          Contact Id

                          <img src="../../../../assets/images/arrow-down.png" alt="" />
                        </th>

                        <th>Status</th>

                        <th>Job Change Detected</th>

                        <th>Person Enrich Status</th>

                        <th>Company Enrich Status</th>

                        <th>Email</th>

                        <th class="td-status-msg">Message</th>

                        <th>Curl Link</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(data, index) in success_contacts" :key="index">
                        <td>
                          <a v-bind:href="generateLink(
      data.contact_id,

      enrichmentData.portal_id
    )
      " target="_blank">{{ data.contact_id }}</a>
                        </td>

                        <td class="status-td completed ">
                          <span>{{ data.status }}</span>
                        </td>

                        <td style="text-align: center">
                          <span v-if="data && 'job_detected' in data">
                            <span v-if="data.job_detected === true">
                              <img src="../../../../assets/images/true-item-icon.svg" alt="" />
                            </span>

                            <span v-else>
                              <!-- <img
                                src="../../../../assets/images/cross-item-icon.svg"
                                alt=""
                              /> -->
                              <img src="../../../../assets/images/icons/minus.svg" alt=""
                                style="height: 20px; width: 20px;" />
                            </span>
                          </span>

                          <span v-else>
                            ---
                          </span>
                        </td>

                        <td style="text-align: center">
                          <span v-if="data && 'person_enrich_status' in data">
                            <span v-if="data.person_enrich_status === true">
                              <img src="../../../../assets/images/true-item-icon.svg" alt="" />
                            </span>

                            <span v-else>
                              <!-- <img
                                src="../../../../assets/images/cross-item-icon.svg"
                                alt=""
                              /> -->
                              <img src="../../../../assets/images/icons/minus.svg" alt=""
                                style="height: 20px; width: 20px;" />
                              <span class="info-icon-tooltip" :data-tooltip="data.additionalMessage">
                                <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z"
                                    fill="#1C274C" />
                                </svg>
                              </span>
                            </span>
                          </span>

                          <span v-else>
                            ---
                          </span>
                        </td>

                        <td class="checkbox-status-col" style="text-align: center">
                          <span v-if="data && 'company_enrich_status' in data">
                            <span v-if="data.company_enrich_status === true">
                              <img src="../../../../assets/images/true-item-icon.svg" alt="" />
                            </span>

                            <span v-else>
                              <!-- <img
                                src="../../../../assets/images/cross-item-icon.svg"
                                alt=""
                              /> -->

                              <img src="../../../../assets/images/icons/minus.svg" alt=""
                                style="height: 20px; width: 20px;" />
                            </span>
                          </span>

                          <span v-else>
                            ---
                          </span>
                        </td>

                        <td v-html="highlightSearchText(data.contact_email)"></td>

                        <td class="message-check-col">{{ data.message }}</td>

                        <td>
                          <span v-if="data.curl ==
      'The cURL request is not initiated because the contact does not contain an email address.' ||
      data.curl ==
      'No curl request is initiated when the contact information includes a Gmail address.' ||
      data.curl ==
      'The curl request is not being made because the contact does not contain the required properties' ||
      data.curl === ''
      ">{{
      data.curl || "No curl request generated yet"
    }}</span>

                          <button v-else class="databtn" @click="copyCurl(data.curl)">
                            <img src="../../../../assets/images/copy-item-icon.svg" alt="" />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div v-if="!currentlyApiLoading" class="pagination ">
                <div class="main-page">
                  <p>
                    Displaying

                    <strong> {{ this.success_contacts.length }} </strong> out of

                    <strong> {{ this.success_contacts_count }} </strong>
                  </p>

                  <div>
                    <span>
                      <strong class="mr-2">{{ currentPage }} -

                        {{ success_contacts_pages }}
                      </strong></span>

                    <button :class="currentPage === 1
        ? 'disableContactEnrich previous-btn'
        : 'activeContactEnrich previous-btn'
      " @click="pageChange(currentPage - 1)" :disabled="currentPage === 1">
                      <img src="../../../../assets/images/arrow-left-icon.png" alt="" />
                    </button>

                    <button :class="success_contacts_pages === currentPage
        ? 'disableContactEnrich next-btn'
        : 'activeContactEnrich next-btn'
      " @click="pageChange(currentPage + 1)" :disabled="this.success_contacts_pages === this.currentPage
      ">
                      <img src="../../../../assets/images/right-icon.png" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>

          <!-- Tab API Error -->

          <b-tab :title="'API Error (' + this.api_error_count + ')'" @click="handleTabClick('api_error', 1)">
            <div>
              <div class="start-table text-center mb-5">
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>
                </div>

                <div v-else>
                  <div v-if="this.api_error.length == 0" class="start-table text-center py-4">
                    <p>No contact found</p>
                  </div>

                  <table v-if="!currentlyApiLoading && api_error.length > 0" class="first-tables table-new">
                    <thead>
                      <tr>
                        <th>
                          Contact Id

                          <img src="../../../../assets/images/arrow-down.png" alt="" />
                        </th>

                        <th>Status</th>

                        <th>Email</th>

                        <th class="td-status-msg">Message</th>

                        <th>Curl Link</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(data, index) in this.api_error" :key="index">
                        <td>
                          <a v-bind:href="generateLink(
      data.contact_id,

      enrichmentData.portal_id
    )
      " target="_blank">{{ data.contact_id }}</a>
                        </td>

                        <td class="status-td duplicate">
                          <span>API Error</span>
                        </td>

                        <td v-html="highlightSearchText(data.contact_email)"></td>

                        <td>
                          <span>{{ data.message }}</span>
                        </td>

                        <td>
                          <span v-if="data.curl ==
      'The cURL request is not initiated because the contact does not contain an email address.' ||
      data.curl ==
      'No curl request is initiated when the contact information includes a Gmail address.' ||
      data.curl ==
      'The curl request is not being made because the contact does not contain the required properties' ||
      data.curl === ''
      ">{{
      data.curl || "No curl request generated yet"
    }}</span>

                          <button v-else class="databtn" @click="copyCurl(data.curl)">
                            <img src="../../../../assets/images/copy-item-icon.svg" alt="" />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div v-if="!currentlyApiLoading" class="pagination ">
                <div class="main-page">
                  <p>
                    Displaying

                    <strong>{{ this.api_error.length }} </strong> out of

                    <strong> {{ this.api_error_count }}</strong>
                  </p>

                  <div>
                    <span>
                      <strong class="mr-2">{{ currentPage }} -

                        {{ api_error_contacts_pages }}
                      </strong></span>

                    <button :class="currentPage === 1
        ? 'disableContactEnrich previous-btn'
        : 'activeContactEnrich previous-btn'
      " @click="pageChange(currentPage - 1)" :disabled="currentPage === 1">
                      <img src="../../../../assets/images/arrow-left-icon.png" alt="" />
                    </button>

                    <button :class="api_error_contacts_pages === currentPage
        ? 'disableContactEnrich next-btn'
        : 'activeContactEnrich next-btn'
      " @click="pageChange(currentPage + 1)" :disabled="this.api_error_contacts_pages === this.currentPage
      ">
                      <img src="../../../../assets/images/right-icon.png" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>

          <!-- Tab Gmail Contacts -->

          <!-- <b-tab
            :title="'Gmail Contacts (' + this.gmail_contacts_count + ')'"
            @click="handleTabClick('gmail_contacts', 1)"
          >
            <div>
              <div class="start-table text-center mb-5">
                <div v-if="currentlyApiLoading">
                  <div
                    class="skeleton-loder skeleton-table-h"
                    style="height: 60px;"
                  ></div>

                  <div
                    class="skeleton-loder skeleton-table-h"
                    style="height: 60px;"
                  ></div>

                  <div
                    class="skeleton-loder skeleton-table-h"
                    style="height: 60px;"
                  ></div>

                  <div
                    class="skeleton-loder skeleton-table-h"
                    style="height: 60px;"
                  ></div>

                  <div
                    class="skeleton-loder skeleton-table-h"
                    style="height: 60px;"
                  ></div>

                  <div
                    class="skeleton-loder skeleton-table-h"
                    style="height: 60px;"
                  ></div>

                  <div
                    class="skeleton-loder skeleton-table-h"
                    style="height: 60px;"
                  ></div>

                  <div
                    class="skeleton-loder skeleton-table-h"
                    style="height: 60px;"
                  ></div>

                  <div
                    class="skeleton-loder skeleton-table-h"
                    style="height: 60px;"
                  ></div>

                  <div
                    class="skeleton-loder skeleton-table-h"
                    style="height: 60px;"
                  ></div>

                  <div
                    class="skeleton-loder skeleton-table-h"
                    style="height: 60px;"
                  ></div>

                  <div
                    class="skeleton-loder skeleton-table-h"
                    style="height: 60px;"
                  ></div>

                  <div
                    class="skeleton-loder skeleton-table-h"
                    style="height: 60px;"
                  ></div>
                </div>

                <div v-else>
                  <div
                    v-if="gmail_contacts.length == 0"
                    class="start-table text-center py-4"
                  >
                    <p>No contact found</p>
                  </div>
                </div>

                <table
                  v-if="!currentlyApiLoading && gmail_contacts.length > 0"
                  class="first-tables table-new"
                >
                  <thead>
                    <tr>
                      <th>
                        Contact Id

                        <img
                          src="../../../../assets/images/arrow-down.png"
                          alt=""
                        />
                      </th>

                      <th>Status</th>

                      <th>Email</th>

                      <th class="td-status-msg">Message</th>

                      <th>Curl Link</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(data, index) in gmail_contacts" :key="index">
                      <td>
                        <a
                          v-bind:href="
                            generateLink(
                              data.contact_id,

                              enrichmentData.portal_id
                            )
                          "
                          target="_blank"
                          >{{ data.contact_id }}</a
                        >
                      </td>

                      <td class="status-td duplicate">
                        <span>Error</span>
                      </td>

                      <td v-html="highlightSearchText(data.contact_email)"></td>

                      <td>{{ data.message }}</td>

                      <td>
                        <span
                          v-if="
                            data.curl ==
                              'The cURL request is not initiated because the contact does not contain an email address.' ||
                              data.curl ==
                                'No curl request is initiated when the contact information includes a Gmail address.' ||
                              data.curl ==
                                'The curl request is not being made because the contact does not contain the required properties' ||
                              data.curl === ''
                          "
                          >{{
                            data.curl || "No curl request generated yet"
                          }}</span
                        >

                        <button
                          v-else
                          class="databtn"
                          @click="copyCurl(data.curl)"
                        >
                          <img
                            src="../../../../assets/images/copy-item-icon.svg"
                            alt=""
                          />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div v-if="gmail_contacts.length > 0" class="pagination ">
                <div class="main-page">
                  <p>
                    Displaying

                    <strong> {{ this.gmail_contacts.length }} </strong> out of

                    <strong> {{ this.gmail_contacts_count }} </strong>
                  </p>

                  <div>
                    <span>
                      <strong class="mr-2"
                        >{{ currentPage }} -

                        {{ gmail_contacts_pages }}
                      </strong></span
                    >

                    <button
                      :class="
                        currentPage === 1
                          ? 'disableContactEnrich previous-btn'
                          : 'activeContactEnrich previous-btn'
                      "
                      @click="pageChange(currentPage - 1)"
                      :disabled="currentPage === 1"
                    >
                      <img
                        src="../../../../assets/images/arrow-left-icon.png"
                        alt=""
                      />
                    </button>

                    <button
                      :class="
                        gmail_contacts_pages === currentPage
                          ? 'disableContactEnrich next-btn'
                          : 'activeContactEnrich next-btn'
                      "
                      @click="pageChange(currentPage + 1)"
                      :disabled="this.gmail_contacts_pages === this.currentPage"
                    >
                      <img
                        src="../../../../assets/images/right-icon.png"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </b-tab> -->

          <!-- Tab Contacts With Out emails -->

          <b-tab :title="'No Emails (' + this.contacts_without_email_count + ')'"
            @click="handleTabClick('contacts_without_email', 1)">
            <div>
              <div class="start-table text-center mb-5">
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>
                </div>

                <div v-else>
                  <div v-if="contacts_without_email.length == 0" class="start-table text-center py-4">
                    <p>No contact found</p>
                  </div>

                  <table v-if="!currentlyApiLoading && contacts_without_email.length > 0
      " class="first-tables table-new">
                    <thead>
                      <tr>
                        <th>
                          Contact Id

                          <img src="../../../../assets/images/arrow-down.png" alt="" />
                        </th>

                        <th>Status</th>

                        <th>Email</th>

                        <th class="td-status-msg">Message</th>

                        <th>Curl Link</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(data, index) in contacts_without_email" :key="index">
                        <td>
                          <a v-bind:href="generateLink(
      data.contact_id,

      enrichmentData.portal_id
    )
      " target="_blank">{{ data.contact_id }}</a>
                        </td>

                        <td class="status-td duplicate">
                          <span>Error</span>
                        </td>

                        <td>
                          --
                        </td>

                        <td>{{ data.message }}</td>

                        <td>
                          <span v-if="data.curl ==
      'The cURL request is not initiated because the contact does not contain an email address.' ||
      data.curl ==
      'No curl request is initiated when the contact information includes a Gmail address.' ||
      data.curl ==
      'The curl request is not being made because the contact does not contain the required properties' ||
      data.curl === ''
      ">{{
      data.curl || "No curl request generated yet"
    }}</span>

                          <button v-else class="databtn" @click="copyCurl(data.curl)">
                            <img src="../../../../assets/images/copy-item-icon.svg" alt="" />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div v-if="contacts_without_email.length > 0" class="pagination ">
                <div class="main-page">
                  <p>
                    Displaying

                    <strong> {{ this.contacts_without_email.length }} </strong>

                    out of

                    <strong> {{ this.contacts_without_email_count }} </strong>
                  </p>

                  <div>
                    <span>
                      <strong class="mr-2">{{ currentPage }} -

                        {{ contacts_without_email_pages }}
                      </strong></span>

                    <button :class="currentPage === 1
        ? 'disableContactEnrich previous-btn'
        : 'activeContactEnrich previous-btn'
      " @click="pageChange(currentPage - 1)" :disabled="currentPage === 1">
                      <img src="../../../../assets/images/arrow-left-icon.png" alt="" />
                    </button>

                    <button :class="contacts_without_email_pages === currentPage
        ? 'disableContactEnrich next-btn'
        : 'activeContactEnrich next-btn'
      " @click="pageChange(currentPage + 1)" :disabled="this.contacts_without_email_pages === this.currentPage
      ">
                      <img src="../../../../assets/images/right-icon.png" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>

          <!-- Tab Contacts With Out properties -->

          <b-tab :title="'No Properties (' + this.contacts_without_properties_count + ')'
      " @click="handleTabClick('contacts_without_properties', 1)">
            <div>
              <div class="start-table text-center mb-5">
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>

                  <div class="skeleton-loder skeleton-table-h" style="height: 60px;"></div>
                </div>

                <div v-else>
                  <div v-if="contacts_without_properties.length == 0" class="start-table text-center py-4">
                    <p>No contact found</p>
                  </div>

                  <table v-if="!currentlyApiLoading &&
      contacts_without_properties.length > 0
      " class="first-tables table-new">
                    <thead>
                      <tr>
                        <th>
                          Contact Id

                          <img src="../../../../assets/images/arrow-down.png" alt="" />
                        </th>

                        <th>Status</th>

                        <th>Email</th>

                        <th class="td-status-msg">Message</th>

                        <th>Curl Link</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(data, index) in contacts_without_properties" :key="index">
                        <td>
                          <a v-bind:href="generateLink(
      data.contact_id,

      enrichmentData.portal_id
    )
      " target="_blank">{{ data.contact_id }}</a>
                        </td>

                        <td class="status-td duplicate">
                          <span>Error</span>
                        </td>

                        <td>
                          {{
      data.message === "Lacks an email address."
        ? "----"
        : data.contact_email
    }}
                        </td>

                        <td>{{ data.message }}</td>

                        <td>
                          <span v-if="data.curl ==
      'The cURL request is not initiated because the contact does not contain an email address.' ||
      data.curl ==
      'No curl request is initiated when the contact information includes a Gmail address.' ||
      data.curl ==
      'The curl request is not being made because the contact does not contain the required properties' ||
      data.curl === ''
      ">{{
      data.curl || "No curl request generated yet"
    }}</span>

                          <button v-else class="databtn" @click="copyCurl(data.curl)">
                            <img src="../../../../assets/images/copy-item-icon.svg" alt="" />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div v-if="contacts_without_properties.length > 0" class="pagination ">
                <div class="main-page">
                  <p>
                    Displaying

                    <strong>
                      {{ this.contacts_without_properties.length }}
                    </strong>

                    out of

                    <strong>
                      {{ this.contacts_without_properties_count }}
                    </strong>
                  </p>

                  <div>
                    <span>
                      <strong class="mr-2">{{ currentPage }} -

                        {{ contacts_without_properties_pages }}
                      </strong></span>

                    <button :class="currentPage === 1
        ? 'disableContactEnrich previous-btn'
        : 'activeContactEnrich previous-btn'
      " @click="pageChange(currentPage - 1)" :disabled="currentPage === 1">
                      <img src="../../../../assets/images/arrow-left-icon.png" alt="" />
                    </button>

                    <button :class="contacts_without_properties_pages === currentPage
        ? 'disableContactEnrich next-btn'
        : 'activeContactEnrich next-btn'
      " @click="pageChange(currentPage + 1)" :disabled="this.contacts_without_properties_pages ===
      this.currentPage
      ">
                      <img src="../../../../assets/images/right-icon.png" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>

      <div class="row">
        <modal name="delete-modal" class="modaldata copy-modal">
          <div class="enrich-head">
            <img aria-hidden="true" @click="closeCopyToNewModal" src="../../../../assets/images/popup-close.png" />

            <!-- <i
    
        
    
                    class="fa fa-times"
    
        
    
                    aria-hidden="true"
    
        
    
                    @click="closeCopyToNewModal"
    
        
    
                  ></i> -->
          </div>

          <div class="enrichmodal newenrichmodal">
            <div>
              <h4 style="text-align: center;  ">
                Are you sure,
              </h4>

              <p class="modal-subheading">
                {{
      this.startEnrichmentSelectedButtonType == "Completed"
        ? "You want to copy the enrichment with same settings."
        : this.startEnrichmentSelectedButtonType == "Error"
          ? " You want to start the enrichment."
          : "You want to Completed the enrichment."
    }}
              </p>
            </div>

            <div class="action_button" style="text-align: center;">
              <button @click="startEnrichment()" class="action_perform secondary-btn-outline">
                Yes
              </button>

              <button @click="closeCopyToNewModal" class="action_perform secondary-btn-outline">
                No
              </button>
            </div>
          </div>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import io from "socket.io-client";
import { BCard, BTabs, BTab } from "bootstrap-vue";
import Vue from "vue";
import { GET_CONTACT_ENRICHED_LOGS } from "../../../store/constant/actions.type";
import {
  CONTACT_PROCESSED_STATUS,
  STOP_ENRICHMENT,
} from "../../../store/constant/actions.type";
import VueApexCharts from "vue-apexcharts";
import { analyticsService } from "../../common/services/analytics.service.js";
import { loadingService } from "../../common/services/loading.service";
import {
  START_HUBSPOT_ENRICHMENT,
  GET_ENRICH_CONTACT_LIST,
} from "../../../store/constant/actions.type.js";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
export default {
  name: "enrich-flipped-contact",
  components: {
    BTabs,
    BTab,
    BCard,
  },
  data() {
    return {
      enrichment_id: 0,
      enrichmentData: {},
      activeTab: "to_process_contacts",
      user_detail: {},
      title: "",
      message: "",
      perPage: 10,
      currentPage: 1,
      to_process_contacts: [],
      success_contacts: [],
      // gmail_contacts: [],
      contacts_without_email: [],
      api_error: [],
      contacts_without_properties: [],
      totalCount: 0,
      to_process_contacts_count: 0,
      success_contacts_count: 0,
      // gmail_contacts_count: 0,
      contacts_without_email_count: 0,
      api_error_count: 0,
      contacts_without_properties_count: 0,
      estimatedTime: 0,
      to_process_contacts_pages: 0,
      success_contacts_pages: 0,
      // gmail_contacts_pages: 0,
      contacts_without_email_pages: 0,
      api_error_contacts_pages: 0,
      contacts_without_properties_pages: 0,
      showProgressLoader: false,
      actionUser: {},
      actionText: "",
      creditUsed: 0,
      searchEmail: "",
      startEnrichmentSelectedButtonType: "",
      currentlyApiLoading: true,
      pushHubspot: false,
      chartOptions: {
        chart: {
          legend: {
            show: false,
          },
          id: "vuechart-example",
        },
        labels: [],
        colors: [
          "#2589ff",
          "#4CAF50",
          "#FF0000",
          "#FFC107",
          "#FFAE00",
          "#FF008B",
        ],
      },
    };
  },
  computed: {
    hasCounts() {
      // Check if any of the counts are greater than 0
      return (
        this.to_process_contacts_count > 0 ||
        this.success_contacts_count > 0 ||
        this.api_error_count > 0 ||
        // this.gmail_contacts_count > 0 ||
        this.contacts_without_email_count > 0 ||
        this.contacts_without_properties_count > 0
      );
    },
    remainingTime() {
      const hours = Math.floor(this.estimatedTime / 3600);
      const minutes = Math.floor((this.estimatedTime % 3600) / 60);
      const seconds = this.estimatedTime % 60;
      return `${hours} hours, ${minutes} minutes, ${seconds} seconds`;
    },
  },
  methods: {
    highlightSearchText(text) {
      if (!this.searchEmail) {
        return text;
      }
      // Create a regular expression to match the search text
      const regex = new RegExp(this.searchEmail, "gi");
      // Replace the search text with a span element for highlighting
      return text.replace(
        regex,
        (match) => `<span class="highlight">${match}</span>`
      );
    },
    getSearchcontact() {
      if (this.searchEmail.length >= 3) {
        this.handleTabClick(this.activeTab, 1);
      }
    },
    clearSearch() {
      this.searchEmail = "";
      this.handleTabClick(this.activeTab, 1);
    },
    pageChange(pageNumber) {
      this.currentPage = pageNumber;
      this.handleTabClick(this.activeTab, pageNumber);
    },
    generateLink(id, portal_id) {
      return `https://app.hubspot.com/contacts/${portal_id}/record/0-1/${id}`;
    },
    closeCopyToNewModal() {
      this.$modal.hide("delete-modal");
    },
    hanldeCopyToNewModal(type) {
      this.$modal.show("delete-modal");
      this.startEnrichmentSelectedButtonType = type;
    },
    startEnrichment() {
      let idArray;
      if (this.enrichmentData.selectedListJsonString) {
        const dataArray = JSON.parse(
          this.enrichmentData.selectedListJsonString
        );
        idArray = dataArray.map((item) => item.id);
      }

      let email = localStorage.getItem("user_email");
      let userCredit = this.user_detail?.currentCredit;
      let segmentData = {
        email: email,
        userId: email,
        pushOnHubspot: this.pushHubspot,
        autoEnrich: true,
        userCredit,
      };
      // if (this.autoEnrich) {
      segmentData["companyPremium"] = this.enrichmentData.company_premium;
      segmentData["companyFrench"] = this.enrichmentData.company_french;
      segmentData["companyFull"] = this.enrichmentData.company_full;
      segmentData["deepTraffic"] = this.enrichmentData.deep_traffic;
      // }
      analyticsService.connectHubspot(this.user_detail, segmentData);
      delete segmentData.userCredit;
      // if (this.autoEnrich) {
      let enrichmentPayload = {
        enrichFutureContact: this.enrichmentData.enrichFutureContact
          ? true
          : false,
        enrichAllExistingContact: this.enrichmentData.enrichAllExistingContact
          ? true
          : false,
        listType: this.enrichmentData.listType,
        enrichmentName: this.enrichmentData.enrichmentName,
        companyPremium: this.enrichmentData.company_premium ? true : false,
        companyFrench: this.enrichmentData.company_french ? true : false,
        companyFull: this.enrichmentData.company_full ? true : false,
        integration_name: "hubspot",
        createAssociateNewComapanyIfJobChangeDetect: this.enrichmentData
          .associate_company
          ? true
          : false,
        taskCreationOptional: this.enrichmentData.create_task ? true : false,
        enrichAllHubspotContact: this.enrichmentData.enrich_all_contacts
          ? true
          : false,
        selectedContactList: idArray,
        selectedListJsonString: this.enrichmentData.selectedListJsonString,
        enrichAlreadyEnrichedContacts: this.enrichmentData.already_enriched
          ? true
          : false,
        deepTraffic: this.enrichmentData.deep_traffic ? true : false,
        taskPriorityData: this.enrichmentData.task_priority,
        taskTypeData: this.enrichmentData.task_type,
        taskAssignToOwner: this.enrichmentData.taskAssignToOwner,
      };
      if (this.enrichmentData.create_task) {
        enrichmentPayload.taskBodyData = this.enrichmentData.task_body;
        enrichmentPayload.taskSubjectData = this.enrichmentData.task_subject;
        enrichmentPayload.taskPriorityData = this.enrichmentData.task_priority;
        enrichmentPayload.taskTypeData = this.enrichmentData.task_type;
        enrichmentPayload.taskAssignToOwner = this.enrichmentData.taskAssignToOwner;
      } else {
        enrichmentPayload.taskBodyData = null;
        enrichmentPayload.taskSubjectData = null;
        enrichmentPayload.taskPriorityData = null;
        enrichmentPayload.taskTypeData = null;
        enrichmentPayload.taskAssignToOwner = null;
      }
      if (this.enrichmentData.enrich_all_contacts) {
        enrichmentPayload["selectedContactList"] = [];
        enrichmentPayload["selectedListJsonString"] = "";
      }
      loadingService.setloader(true);
      this.$store
        .dispatch(START_HUBSPOT_ENRICHMENT, enrichmentPayload)
        .then((data) => {
          if (data && data.code == "success") {
            this.flashMessage.success({
              message: data.description,
            });
            this.$modal.hide("delete-modal");

            // this.hubspotIntegrated = false;
            // this.createAssociateNewComapanyIfJobChangeDetect = false;
            // this.taskCreationOptional = false;

            this.getEnrichmentData(email);
          } else if (data.code == "Info") {
            loadingService.setloader(false);
            this.flashMessage.error({
              message: data.description,
            });
          }
        })
        .catch((err) => {
          loadingService.setloader(false);
          this.hubspotIntegrated = false;
          if (err && err.data && err.data.message) {
            this.flashMessage.error({
              message: err.data.message,
            });
          }
        });
      // } else {
      //   this.hubspotIntegrated = false;
      // }
    },
    getEnrichmentData(email) {
      let payload = {
        offset: 10,
        page: 1,
      };
      this.$store
        .dispatch(GET_ENRICH_CONTACT_LIST, payload)
        .then((data) => {
          loadingService.setloader(false);
          if (data.status == "success" && data.data.data.length > 0) {
            let enrichmentList = data.data.data;
            let inProgressContact = enrichmentList.filter(
              (x) => x.status == "In Progress" && x.email == email
            );
            this.$store.dispatch("changeApitime", 1);
            this.$router.push({
              name: "enrich-details-contact",
              params: { id: inProgressContact[0].id },
            });
            window.location.reload();
          }
        })
        .catch((error) => {
          throw error;
        });
    },
    modelOpen(postData) {
      if (postData.status == "Paused") {
        this.actionText = "start Enrichment";
      } else if (postData.status == "In Progress") {
        this.actionText = "Pause Enrichment";
      } else {
        postData = {};
      }
      this.actionUser = postData;
      this.$modal.show("my-modal");
    },

    handleRouteChange() {
      this.$router.push({ name: "enrich-contact" });
    },
    getDateInformat(d) {
      if (d) {
        let date = new Date(d);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let m, dy;
        if (month <= 9) m = "0" + month;
        else m = month;
        if (day <= 9) dy = "0" + day;
        else dy = day;
        return `${year}-${m}-${dy}`;
      } else {
        return null;
      }
    },
    getformatDate(value) {
      let dateObject = new Date(value);

      let timeIST = dateObject.toLocaleTimeString("en-IN", dateObject);

      return timeIST;
    },
    getListName(value) {
      if (value && value != "") {
        const list = JSON.parse(value);
        return list.map((item) => item.name).join(", ");
      } else {
        return "-";
      }
    },
    enrichAction() {
      loadingService.setloader(true);
      let id = this.$route.params.id;
      let payload = {
        id: id,
        offset: 10,
        page: 1,
      };
      this.$modal.hide("my-modal");
      let data = {
        id: this.actionUser.id,
        status: this.actionUser.status,
        file_name: this.actionUser.file_name,
      };

      if (this.actionUser.status == "Paused") {
        this.$store
          .dispatch(CONTACT_PROCESSED_STATUS, data)
          .then((response) => {
            if (response) {
              this.getEnrichedLogs(payload);
              this.flashMessage.success({
                message: response.result,
              });
            }
          })
          .catch((err) => {
            loadingService.setloader(false);
            this.flashMessage.error({
              title: "Error",
              message: err.error,
            });
          });
      } else if (this.actionUser.status == "In Progress") {
        this.$store
          .dispatch(STOP_ENRICHMENT, data)
          .then((response) => {
            if (response.result == "Enrichment Paused Successfully.") {
              this.getEnrichedLogs(payload);
              this.flashMessage.success({
                message: response.result,
              });
            }
          })
          .catch((err) => {
            loadingService.setloader(false);
            this.flashMessage.error({
              title: "Error",
              message: err.error,
            });
          });
      }
    },
    closeModal() {
      this.$modal.hide("my-modal");
    },
    handleTabClick(status, pageNumber, showLoader = true) {
      if (pageNumber == 1) {
        this.currentPage = 1;
      }
      this.activeTab = status;
      this.currentlyApiLoading = showLoader;
      // this.currentPage = 1;
      let payload = {
        status: status,
        offset: 10,
        s_keyword: this.searchEmail,
        page: pageNumber,
        enrichment_id: this.enrichment_id,
      };
      this.$store
        .dispatch(GET_CONTACT_ENRICHED_LOGS, payload)
        .then((response) => {
          this.currentlyApiLoading = false;
          // loadingService.setloader(false);
          if (response.status == "success") {
            this.setCount(response.count);
            this.setRecords(status, response.data);
            this.enrichmentData = response.enrichment[0];
            this.estimatedTime = this.enrichmentData.estimated_time_to_complete;
            this.chartOptions.labels[0] = `In Progress`;
            this.chartOptions.labels[1] = `Success`;
            this.chartOptions.labels[2] = `API Error`;
            // this.chartOptions.labels[3] = `Gmail Contacts`;
            this.chartOptions.labels[3] = `No Emails`;
            this.chartOptions.labels[4] = `No Properties`;

            // loadingService.setloader(false);
          } else {
            // loadingService.setloader(false);
          }
        })
        .catch((err) => {
          loadingService.setloader(false);
          if (err != undefined) {
            this.flashMessage.error({
              title: "Error",
              message: err.data.description,
            });
          }
        });
    },

    setCount(status_counts) {
      this.to_process_contacts_count = 0;
      this.to_process_contacts_pages = 0;
      this.success_contacts_count = 0;
      this.success_contacts_pages = 0;
      // this.gmail_contacts_count = 0;
      // this.gmail_contacts_pages = 0;
      this.contacts_without_email_count = 0;
      this.contacts_without_email_pages = 0;
      this.api_error_count = 0;
      this.api_error_contacts_pages = 0;
      this.contacts_without_properties_count = 0;
      this.contacts_without_properties_pages = 0;

      status_counts.forEach((item) => {
        switch (item.status) {
          case "to_process_contacts":
            this.to_process_contacts_count = parseInt(item.count);
            this.to_process_contacts_pages = this.calculateTotalPages(
              this.to_process_contacts_count
            );
            this.to_process_contacts_count / 10;
            break;
          case "success":
            this.success_contacts_count = parseInt(item.count);
            this.success_contacts_pages = this.calculateTotalPages(
              this.success_contacts_count
            );
            break;
          // case "gmail_contacts":
          //   this.gmail_contacts_count = parseInt(item.count);
          //   this.gmail_contacts_pages = this.calculateTotalPages(
          //     this.gmail_contacts_count
          //   );
          //   break;
          case "contacts_without_email":
            this.contacts_without_email_count = parseInt(item.count);
            this.contacts_without_email_pages = this.calculateTotalPages(
              this.contacts_without_email_count
            );
            break;
          case "api_error":
            this.api_error_count = parseInt(item.count);
            this.api_error_contacts_pages = this.calculateTotalPages(
              this.api_error_count
            );
            break;
          case "contacts_without_properties":
            this.contacts_without_properties_count = parseInt(item.count);
            this.contacts_without_properties_pages = this.calculateTotalPages(
              this.contacts_without_properties_count
            );
            break;
          default:
            break;
        }
      });
    },

    setRecords(status, records) {
      this.success_contacts = [];
      // this.gmail_contacts = [];
      this.to_process_contacts = [];
      this.api_error = [];
      this.contacts_without_email = [];
      this.contacts_without_properties = [];

      switch (status) {
        case "success":
          this.success_contacts = records;
          break;
        // case "gmail_contacts":
        //   this.gmail_contacts = records;
        //   break;
        case "to_process_contacts":
          this.to_process_contacts = records;
          break;
        case "api_error":
          this.api_error = records;
          break;
        case "contacts_without_email":
          this.contacts_without_email = records;
          break;
        case "contacts_without_properties":
          this.contacts_without_properties = records;
          break;

        default:
          break;
      }
    },

    calculateTotalPages(totalRecords) {
      return Math.ceil(totalRecords / 10);
    },

    copyCurl(curl) {
      const textarea = document.createElement("textarea");
      textarea.value = curl;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);

      // Optionally, provide user feedback (e.g., display a message)
      this.flashMessage.success({
        title: "success",
        message: "Curl Copied to ClipBoard",
        time: 3000,
      });
    },
  },
  mounted() {
    const url = process.env.VUE_APP_NODE_URL;
    const socket = io(url, {
      path: "/dgsocket",
    });
    socket.on("initialSoc", (enrichment_id) => {
      debugger;
      if (this.enrichment_id == enrichment_id) {
        localStorage.removeItem("showW");
        this.showProgressLoader = false;
        this.handleTabClick(this.activeTab, this.currentPage);
      }
    });
    socket.on("enrichmentEvent", (enrichment_id) => {
      if (this.enrichment_id == enrichment_id) {
        this.$eventBus.$emit("callCreditMethod");
        this.handleTabClick(this.activeTab, this.currentPage, false);
      }
    });
    socket.on("final_status", (data) => {
      if (this.enrichmentData.file_name === data) {
        this.$eventBus.$emit("callCreditMethod");
        this.enrichmentData.status = "Completed";
      }
    });
    socket.on("enrichment_error", (data) => {
      if (this.enrichmentData.file_name === data.key) {
        this.enrichmentData.status = "Error";
        this.enrichmentData.error_message = data.message;
      }
    });
  },
  beforeMount() {
    this.user_detail = this.$store.getters.getstoreUser;
    this.enrichment_id = this.$route.params.id;
    if (localStorage.getItem("showW")) {
      this.showProgressLoader = true;
    } else {
      this.handleTabClick(this.activeTab, 1);
    }
  },
};
</script>
<style scoped>
body {
  font-family: Semi Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-size: 16px;
}

.top {
  display: flex;
  align-items: center;
  gap: 14px;
  justify-content: space-between;
}

.top p {
  margin: 0px 0px;
  display: inline-block;
  vertical-align: middle;
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.table_head {
  background: #3f8aff;
  color: #ffff !important;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  width: 8% !important;
  padding: 2px 4px;
}

td {
  align-content: center;
}

/* tr td {
      width: 16%;
    } */

tr.R1 {
  color: #000000;
  width: 14%;
}

.start-table {
  margin-top: 24px;
  overflow-y: auto;
}

.first-tables {
  width: 100%;
  line-height: 45px;
  color: FFFFFF;
}

.start-table.bottom-area.table-custom {
  padding-top: 0;
}

.start-table.bottom-area.table-custom th {
  font-size: 14px;
  line-height: 1.2;
  vertical-align: middle;
}

/* .completed {
        color: #5BB645;
        border-color: #5BB645;
  
    } */

/* .file_not_found {
        color: #DA4E39;
        border-color: #DA4E39;
    } */

.modaldata ::v-deep .vm--modal {
  padding: 30px !important;
}

.modaldata ::v-deep .vm--modal {
  height: auto !important;
  border-radius: 18px;
}

.modaldata ::v-deep .enrichmodal {
  position: unset;
  transform: translateY(0);
}

.action_button {
  margin-top: 60px;
  margin: auto;
  border: none !important;
}

.action_perform {
  /* width: 40%; */
  margin-left: 20px;
}

.action_perform:first-child {
  margin-left: 0;
}

.in_progress {
  color: #225fee;
}

.duplicate {
  color: #da4e39;
}

.paused {
  color: #225fee;
}

.action_button {
  width: 100%;
  padding: 6px 15px;
}

.enrichmodal {
  position: absolute;
  width: 100%;
  top: 56%;
  transform: translateY(-50%);
}

.enrich-head i {
  color: #003172;
  color: #fff;
}

.enrich-head {
  text-align: right;
  background: #3f6fcc24;
  padding-right: 0px;
}

.vm--modal {
  width: 640px !important;
  height: 170px !important;
}

.table-new tr td {
  line-height: 24px;
  padding: 4px 18px;
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  width: 16%;
}

.table-new tr td:nth-child(1) {
  /* display: block; */
  min-height: 76px;
}

table.first-tables.table-new thead {
  border-bottom: 2px solid #0000000d;
}

.first-tables th {
  text-align: left;
  padding: 2px 18px;
  font-size: 12px;
  color: #7a869a;
  font-weight: 600;
  text-transform: uppercase;
}

.first-tables th:nth-child(2) {
  color: #000;
}

.table-new tr td.status-td span {
  border-radius: 50px;
  display: inline-block;
  padding: 2px 9px 0px;
  font-size: 12px;
  font-weight: 700;
  background: #c7e2bc;
  color: green;
  max-width: 92px;
  width: 100%;
}

td span svg {
  height: 16px;
  widows: 16px;
}

p.completed {
  color: #5bb645;
  border: 1px solid #5bb64529;
  padding: 3px;
  border-radius: 8px;
  background-color: #2ca96812;
}

td.completed span {
  color: #5bb645;
  border: 1px solid #5bb645;
}

td.file_not_found span {
  color: #da4e39;
  border: 1px solid #da4e39;
}

td.in_progress span {
  color: #64b7d5;
  border: 1px solid #64b7d5;
}

td.duplicate span {
  border: 1px solid #da4e39;
  color: #da4e39;
}

.table-new tr td span.processed {
  color: #00a300;
}

.colorGreen {
  color: #00a300;
}

.table-new tr td span.errorProcess {
  color: #ff0000;
}

.start-table p {
  font-weight: 700;
  font-size: 26px;
  color: #000;
  line-height: 32px;
  margin-bottom: 5px;
  text-align: center;
}

button.activeContactEnrich.previous-btn {
  border-radius: 4px 0px 0px 4px;
}

.colorRed {
  color: #ff0000;
}

button.disableContactEnrich.next-btn {
  border-radius: 0px 4px 4px 0px;
}

.action_button {
  border: 1px solid #3f8aff;
  border-radius: 18px;
  background: transparent;
  color: #3f8aff;
  font-weight: 600;
  text-transform: capitalize;
  /* padding: 5px 0px; */
  transition: 0.8s all;
}

.InRed:hover {
  background: #ff0000;
}

.InRed:hover span {
  color: #fff;
}

.InRed {
  border: 1px solid #ff0000;
  color: #ff0000;
  transition: 0.8s all;
}

.InGreen:hover {
  background: #00a300;
}

.InGreen:hover span.colorGreen {
  color: #fff;
}

input .InGreen {
  color: #00a300;
  transition: 0.8s all;
  border: 1px solid #00a300;
  width: 76px;
  font-size: 14px;
}

.main-div {
  background: #ffffff;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px;
}

.pagination .main-page {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.main-div input {
  border: 1px solid #dfe1e6;
  border-radius: 4px;
  outline: none;
}

.start-table p {
  font-weight: 700;
  font-size: 26px;
  color: #000;
  line-height: 32px;
  margin-bottom: 5px;
}

.custom-tooltip {
  cursor: pointer;
}

.td-status-msg {
  width: 550px;
}

.main-details-card {
  display: flex;
  gap: 40px;
  text-align: left;
  background-color: #fff;
  flex-wrap: wrap;
  margin-top: 11px;
}

.main-details-card .single-card-box {
  text-align: center;
}

.single-card-box h4 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
}

.main-div.c-enrichment .card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  /* background-color: rgba(0,0,0,.03); */
  /* border-bottom: 1px solid rgba(0,0,0,.125); */
}

.middle-detail-card {
  margin: 0 0 0px 0;
}

.main-div.c-enrichment .card {
  border-radius: 0px;
  box-shadow: none;
}

.main-page button.activeContactEnrich img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(8%) saturate(7500%) hue-rotate(355deg) brightness(103%) contrast(110%);
}

.card-header .nav.nav-pills.card-header-pills {
  justify-content: space-between;
  align-items: center;
  margin: 2px 6px;
}

.action-btn {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: baseline;
}

button.disableContactEnrich.previous-btn {
  border-radius: 4px 0px 0px 4px;
}

button.activeContactEnrich.next-btn {
  border-radius: 4px 0px 0px 4px;
}

button.activeContactEnrich.next-btn {
  border-radius: 0px 4px 4px 0px;
}

@media screen and (max-width: 1450px) {
  .main-details-card {
    display: flex;
    justify-content: space-evenly;
    gap: 21px;
  }

  .card-header .nav.nav-pills.card-header-pills {
    justify-content: space-around;
    align-items: center;
    margin: 2px 0px;
  }
}

@media screen and (max-width: 1450px) {
  .td-status-msg {
    width: 450px;
  }
}

.single-card-box .status-td.completed {
  width: auto;
  text-align: center;
  border-radius: 26.049px;
  background: rgba(63, 138, 255, 0.2);
  border: 0;
  padding: 4px 8px;
  color: #3f8aff;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
}

.single-card-box .action_button {
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.databtn {
  border: none;
  padding: 4px;
  border-radius: 4px;
  background-color: #20b89e;
  color: #fff;
}

.skeleton-loder {
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
  will-change: transform;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  animation: loading 2.6s linear infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.skeleton-table-h {
  /* position: absolute; */
  top: 0px;
  margin-bottom: 12px !important;
}

.jobSwitchClass {
  width: 28%;
  position: absolute;
  right: 1px;
  top: 35px;
}

.toggleLable {
  font-size: 16px;
}

.skeleton-loder:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0));
  animation: shimmer 5s infinite;
  content: "";
  position: absolute;
  background-image: linear-gradient(90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0));
}

.wrapper .main-content {
  background-color: #f3f7fb;
}

.middle-detail-card .card {
  background-color: transparent;
  border: 0;
}

.top {
  border-radius: 0px;
  background: var(--White, #fff);
  /* box-shadow: 0px 4px 10px 0px rgba(63, 209, 255, 0.3); */
  padding: 0 0px;
  margin-bottom: 0px;
  /* padding-left: 0; */
}

.main-div {
  padding: 0;
  background: transparent;
  box-shadow: none;
}

.main-details-card {
  display: flex;
  gap: 40px;
  text-align: left;
  margin-top: 0;
  flex-wrap: nowrap;
  padding-top: 48px;
  padding-bottom: 48px;
}

.wrapper .main-content {
  padding: 32px;
}

.card ::v-deep .leftcards {
  width: 100%;
  padding: 0px;
  border-radius: 15px;
  background: var(--White, #fff);
  /* box-shadow: 0px 4px 10px 0px rgba(63, 209, 255, 0.3); */
  gap: 24px;
  box-shadow: unset;
  display: flex;
  flex-direction: column;
  /* padding-left: 64px; */
  position: relative;
}

/* .leftcards::before {
    content: "";
    border: 1px solid #828282;
    position: absolute;
    left: 40px;
    width: 0px;
    height: 90%;
  } */

/* .main-details-card .single-card-box::before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    left: 31px;
    background: #3f8aff;
    border-radius: 100%;
  } */

.card-body {
  flex: auto;
  min-height: 1px;
  padding: 0;
}

.pagination .main-page button {
  background-color: #1015281c;
  border: none;
  width: 30px;
  min-height: 28px;
}

.main-details-card .single-card-box {
  text-align: center;
  display: flex;
  justify-content: space-between;
  border-radius: 0;
  border: 0.807px solid #d2d2d2;
  background: #f6f6f6;
  align-items: center;
  height: 40px;
  padding: 0 20px;
}

.single-card-box h4 {
  color: #0f3274;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}

.single-card-box p {
  color: #101528;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

/* .single-card-box p.R1 img {
  width: 23px;
  height: 23px;
} */

.middle-setting-box .single-card-box p.R1 img,
table.first-tables.table-new td span img,
.table-new tr td button.databtn img,
.single-card-box p.R1 img {
  width: 16px;
  height: 16px;
}

.main-left-card {
  display: flex;
  /* gap: 15px; */
}

.card ::v-deep .rightchart {
  width: 25%;
  padding: 30px;
  border-radius: 15px;
  background: var(--White, #fff);
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: unset;
}

.rightchart .chartheading {
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  left: 30px;
  top: 30px;
}

.apexcharts-legend {
  position: absolute !important;
  bottom: 1px !important;
  top: unset !important;
  left: 2px !important;
}

.card ::v-deep .rightchart .chart-donut .apexcharts-legend {
  flex-direction: row !important;
  position: absolute !important;
  bottom: 1px !important;
  left: 24px !important;
  top: unset !important;
}

table.first-tables.table-new tr td:first-child {
  width: 40px;
}

table.first-tables.in-progress-table.table-new tr td:first-child {
  width: 16%;
}

.top .action-btn .refresh-btn .secondary-btn-outline {
  padding: 2px 20px 4px;
  background-color: #ffff;
  border: 0;
  color: #253858;
  font-weight: 600;
  border: 1px solid #003172;
  border-radius: 10px;
}

.top .action-btn .single-card-box .secondary-btn-outline {
  padding: 4px 20px;
  background-color: #3f8aff;
  color: #fff;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid transparent;
  /* border: none; */
}

.main-div .card ::v-deep .nav-link.active {
  color: #fff;
  background-color: #000;
}

.main-div .card ::v-deep .card-header {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
}

/* .main-div .card  ::v-deep .card-header .nav.nav-pills{
  margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 30px;
    width: 100%;
    max-width: 582px;
    justify-content: space-between;
    align-items: center;
} */

.main-div .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: 0;
  padding: 30px;
  border-radius: 24.808px;
  background: var(--White, #fff);
  box-shadow: 0px 4.962px 12.404px 0px rgba(63, 209, 255, 0.3);
}

.main-div.c-enrichment .main-div .card .tabs .card-header {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
}

.main-div .card ::v-deep .tabs .card-header .nav.nav-pills {
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  max-width: 930px;
}

.main-div .card ::v-deep .tabs .card-header .nav.nav-pills li.nav-item .nav-link {
  border-radius: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 10px 12px;
  color: #7a869a;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  background-color: #fff;
}

.top .action-btn .refresh-btn .secondary-btn-outline:hover {
  background-color: #4f8bff;
  color: #ffff;
  border: 1px solid transparent;
  transition: all 0.4s;
}

.top .action-btn .single-card-box .secondary-btn-outline:hover {
  background-color: #fff;
  color: #4f8bff;
  border: 1px solid #4f8bff;
  transition: all 0.4s;
}

.top .action-btn .single-card-box .secondary-btn-outline:hover {
  background-color: #fff;
  color: #4f8bff;
  border: 1px solid #4f8bff;
  transition: all 0.4s;
}

.card ::v-deep .vm--modal {
  height: auto !important;
  border-radius: 18px;
}

.card ::v-deep .enrichmodal {
  position: unset;
  transform: translateY(0%);
}

.main-div .card ::v-deep .tabs .card-header .nav.nav-pills li.nav-item .nav-link.active {
  background-color: #0031720f;
  color: #003172;
  border: 1px solid #00317230;
  border-radius: 8px;
  text-align: center;
  font-size: 12px;
  /* max-width: 131px; */
}

.first-tables th:nth-child(2) img {
  position: relative;
  top: -1px;
  left: 3px;
}

/* .main-details-card .single-card-box .R1 {
  width: 30px;
  height: 30px;
 
}  */

.first-tables.table-new thead tr {
  background-color: transparent;
}

.table-new tr:nth-child(even) {
  background-color: #7a869a0a;
  border-top: 1px solid #7a869a17;
  border-bottom: 1px solid #7a869a17;
}

.table-new tr td.duplicate span {
  border-radius: 4px;
  background: rgb(210 76 52 / 10%);
  padding: 4px 15px;
  border: 0;
  color: #d24c34;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.366px;
  letter-spacing: -0.104px;
  text-transform: capitalize;
  width: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  border: 1px solid #ff443b33;
}

.table-new tr td.completed span {
  border-radius: 4px;
  background: rgb(32 184 158 / 10%);
  padding: 4px 15px;
  border: 0;
  color: #5aaf4b;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.366px;
  letter-spacing: -0.104px;
  text-transform: capitalize;
  width: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  border: 1px solid #2ca96830;
}

.table-new tr td .databtn {
  border-radius: 4.406px;
  background: unset;
  color: var(--White, #fff);
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px 15px;
  width: 40%;
}

.action_button.InGreen {
  background: transparent;
}

button.action_button.InGreen .colorGreen {
  color: #fff;
}

.main-div .card .search-container {
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}

.main-div .card .search-container .searchbar {
  width: 94%;
  margin-left: 0;
  padding: 9px 38px;
  border: none;
  background-color: #f7f8fa;
  font-size: 12px;
}

.main-div .card .search-container .searchicon {
  position: absolute;
  left: 15px;
  top: 6px;
}

.table-new tr td.in_progress span {
  border-radius: 4px;
  background: rgb(79 139 255 / 11%);
  padding: 4px 8px;
  border: 0;
  color: #4f8bff;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.366px;
  letter-spacing: -0.104px;
  text-transform: capitalize;
  border: 1px solid #4f8bff29;
}

.leftcards.loaderbox::before {
  border-color: #f0f0f0;
}

.rightchart.skeletonbox {
  height: 100%;
  padding: 0;
  box-shadow: none;
  justify-content: space-between;
}

.rightchart.skeletonbox .skeleton-loder.skeleton-table-h.roundbox {
  height: 350px;
  border-radius: 100%;
  width: 350px;
  position: relative;
  top: 25px;
  left: 45px;
}

.single-card-box .status-td.completed {
  background: rgba(32, 184, 158, 0.2);
  color: #5aaf4b;
  width: auto;
  text-align: center;
  border-radius: 26.049px;
  border: 0;
  padding: 4px 8px;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
}

.single-card-box .status-td.in_progress {
  background: rgba(247, 143, 0, 0.2);
  color: #f9c000;
  width: auto;
  text-align: center;
  border-radius: 26.049px;
  border: 0;
  padding: 4px 8px;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
}

.single-card-box .status-td.duplicate {
  background: rgba(210, 76, 52, 0.2);
  color: #d24c34;
  width: auto;
  text-align: center;
  border-radius: 26.049px;
  border: 0;
  padding: 4px 8px;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
}

.single-card-box .status-td.duplicate.paused {
  background: #b8b8b8;
  color: #000;
}

.innerleftcards .single-card-box {
  margin-bottom: 10px;
}

.main-details-card .single-card-box.body-task-text {
  height: auto;
  padding: 6px 6px;
}

.main-details-card .single-card-box.body-task-text h4 {
  width: 350px;
}

.innerleftcards {
  background: #f6f6f6;
  border: 0.807px solid #d2d2d2;
  border-radius: 4.036px;
}

.innerleftcards .single-card-box {
  background: transparent;
  border: unset;
  padding: 0;
}

.upper-setting-box h6 {
  margin: 0 7px 0 0;
  font-weight: bold;
  font-size: 12px;
}

.upper-setting-box .inner-single-setting span {
  font-size: 12px;
}

.upper-setting-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 12px;
  align-items: center;
  padding: 14px 20px;
  border: 1px solid #ebedf2;
  background-color: #ebedf233;
}

.inner-single-setting {
  min-width: 155px;
  /* margin-top: 10px; */
}

.rightchart .chart-donut {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  /* width: 86%;
  height: 57%; */
  top: 30px;
  text-align: center;
}

.card ::v-deep .apexcharts-canvas svg {
  height: 500px;
}

.card ::v-deep .rightchart .chart-donut foreignObject {
  height: 289px;
}

.inner-single-setting .status-td {
  font-size: 12px;
  padding: 0px 9px;
}

.upper-setting-box p {
  margin: 0;
}

.middle-setting-box .single-card-box {
  background: transparent;
  border: unset;
  width: 47%;
  padding: 0 10px 0 0;
  border-bottom: 1px solid #eee;
}

.middle-setting-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 40px;
}

.middle-setting-box .single-card-box h4 {
  text-align: left;
  font-size: 12px;
}

.main-details-card .bottom-settings-box .single-card-box {
  padding: 0;
  background: transparent;
  border: unset;
  border-bottom: 1px solid #eee;
  margin-bottom: 4px;
}

.main-details-card .bottom-settings-box .single-card-box:last-child {
  margin-bottom: 0px;
  border-bottom: unset;
}

.bottom-settings-box {
  width: 27%;
  margin-left: 20px;
}

.main-details-card .bottom-settings-box .single-card-box.body-task-text {
  align-items: self-start;
}

.body-task-text h4 {
  text-align: left;
}

.body-task-text p {
  text-align: left;
  max-height: 38px;
  overflow: auto;
}

.main-details-card .error-message-main .single-card-box {
  background: transparent;
  padding: 5px;
  border: 1px solid #eee;
}

.inner-single-setting .status-td.duplicate {
  border-radius: 3px;
  color: #d24c34;
  font-weight: 400;
  border: 1px solid #edd4ce;
  background: rgb(210 76 52 / 10%);
}

span.modal-subheading {
  font-size: 16px;
}

.new-resultss {
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pagination .main-page button.activeContactEnrich {
  background-color: #7a869a73 !important;
}

.toggleLable {
  margin: 0;
  margin-right: 10px;
}

.new-resultss .col-6 {
  flex: 0 100%;
  max-width: 100%;
  justify-content: flex-end;
}

.enrich-head {
  position: absolute;
  right: 12px;
  top: 16px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  cursor: pointer;
}

.enrichmodal.newenrichmodal h4 {
  font-weight: 700;
  font-size: 26px;
  color: #000;
}

.vm--modal {
  width: 640px !important;
  height: 214px !important;
  border-radius: 24px;
}

.secondary-btn-outline {
  padding: 3px 13px;
}

p.modal-subheading {
  text-align: center;
}

.main-loader-card {
  display: flex;
  justify-content: space-between;
}

.main-loader-card .rightchart {
  padding: 0;
}

.main-loader-card .skeleton-loder.roundbox {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  margin: 10px;
}

.rightchart-main {
  padding: 0 18px;
  margin-right: 10px;
}

::v-deep .highlight {
  background-color: yellow;
  font-weight: bold;
}

/* ::v-deep .apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
  width: 100%;
  max-width: 240px;
  overflow: auto;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
} */

::v-deep .content {
  width: 98%;
}

.main-div .card .search-container .close-icon {
  position: absolute;
  top: 5px;
  right: 35px;
  cursor: pointer;
}

.main-div .card .search-container .close-icon svg {
  height: 15px;
  width: 15px;
}

.loader-process {
  width: 40px;
  aspect-ratio: 1;
  display: grid;
}

.loader-process::before,
.loader-process::after {
  content: "";
  grid-area: 1/1;
  --c: no-repeat linear-gradient(#046d8b 0 0);
  background: var(--c) 0 0, var(--c) 100% 0, var(--c) 100% 100%, var(--c) 0 100%;
  animation: l10-1 2s infinite linear, l10-2 2s infinite linear;
}

.loader-process::after {
  margin: 25%;
  transform: scale(-1);
}

@keyframes l10-1 {
  0% {
    background-size: 0 4px, 4px 0, 0 4px, 4px 0;
  }

  12.5% {
    background-size: 100% 4px, 4px 0, 0 4px, 4px 0;
  }

  25% {
    background-size: 100% 4px, 4px 100%, 0 4px, 4px 0;
  }

  37.5% {
    background-size: 100% 4px, 4px 100%, 100% 4px, 4px 0;
  }

  45%,
  55% {
    background-size: 100% 4px, 4px 100%, 100% 4px, 4px 100%;
  }

  62.5% {
    background-size: 0 4px, 4px 100%, 100% 4px, 4px 100%;
  }

  75% {
    background-size: 0 4px, 4px 0, 100% 4px, 4px 100%;
  }

  87.5% {
    background-size: 0 4px, 4px 0, 0 4px, 4px 100%;
  }

  100% {
    background-size: 0 4px, 4px 0, 0 4px, 4px 0;
  }
}

@keyframes l10-2 {

  0%,
  49.9% {
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  }

  50%,
  100% {
    background-position: 100% 0, 100% 100%, 0 100%, 0 0;
  }
}

.top-animations-process {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: calc(100vh - 170px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  background: #fff;
}

.process-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.process-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-loading-box {
  margin-top: 50px;
  overflow: hidden;
  height: 50px;
  position: relative;
  width: 1100px;
}

.main-loading-box h2 {
  margin: 0;
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  font-size: 34px;
  top: 0;
  text-align: center;
}

@keyframes slide {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.main-loading-box h2:nth-child(1) {
  animation: slide 4s forwards ease-in-out;
  animation-delay: 3s;
}

.main-loading-box h2:nth-child(2) {
  animation: slide 4s forwards ease-in-out;
  animation-delay: 8s;
}

.main-loading-box h2:nth-child(3) {
  animation: slide 4s forwards ease-in-out;
  animation-delay: 13s;
}

.main-loading-box h2:nth-child(4) {
  animation: slide 4s forwards ease-in-out;
  animation-delay: 18s;
}

.main-loading-box h2:nth-child(5) {
  animation: slide 4s forwards ease-in-out;
  animation-delay: 23s;
}

.main-loading-box h2:nth-child(6) {
  animation: slide 4s forwards ease-in-out;
  animation-delay: 28s;
}

@-webkit-keyframes animate-svg-fill-1 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(37, 137, 255);
  }
}

@keyframes animate-svg-fill-1 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(37, 137, 255);
  }
}

.svg-elem-1 {
  -webkit-animation: animate-svg-fill-1 1s linear 0.8s both;
  animation: animate-svg-fill-1 1s linear 0.8s both;
}

@-webkit-keyframes animate-svg-fill-2 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-svg-fill-2 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.svg-elem-2 {
  -webkit-animation: animate-svg-fill-2 1s linear 0.9s both;
  animation: animate-svg-fill-2 1s linear 0.9s both;
}

@-webkit-keyframes animate-svg-fill-3 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-svg-fill-3 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.svg-elem-3 {
  -webkit-animation: animate-svg-fill-3 1s linear 1s both;
  animation: animate-svg-fill-3 1s linear 1s both;
}

@-webkit-keyframes animate-svg-fill-4 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-svg-fill-4 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.svg-elem-4 {
  -webkit-animation: animate-svg-fill-4 1s linear 1.1s both;
  animation: animate-svg-fill-4 1s linear 1.1s both;
}

@-webkit-keyframes animate-svg-fill-5 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-svg-fill-5 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.svg-elem-5 {
  -webkit-animation: animate-svg-fill-5 1s linear 1.2000000000000002s both;
  animation: animate-svg-fill-5 1s linear 1.2000000000000002s both;
}

@-webkit-keyframes animate-svg-fill-6 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-svg-fill-6 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.svg-elem-6 {
  -webkit-animation: animate-svg-fill-6 1s linear 1.3s both;
  animation: animate-svg-fill-6 1s linear 1.3s both;
}

@-webkit-keyframes animate-svg-fill-7 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-svg-fill-7 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.svg-elem-7 {
  -webkit-animation: animate-svg-fill-7 1s linear 1.4000000000000001s both;
  animation: animate-svg-fill-7 1s linear 1.4000000000000001s both;
}

@-webkit-keyframes animate-svg-fill-8 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-svg-fill-8 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.svg-elem-8 {
  -webkit-animation: animate-svg-fill-8 1s linear 1.5s both;
  animation: animate-svg-fill-8 1s linear 1.5s both;
}

@-webkit-keyframes animate-svg-fill-9 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-svg-fill-9 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.svg-elem-9 {
  -webkit-animation: animate-svg-fill-9 1s linear 1.6s both;
  animation: animate-svg-fill-9 1s linear 1.6s both;
}

.main-loading-svg svg {
  width: 230px;
  height: 90px;
}

.first-tables.table-new th:nth-child(2) {
  color: #7a869a;
}

.first-tables.table-new tr td.status-td {
  width: 6%;
}

.first-tables.table-new tr td.checkbox-status-col {
  width: 20%;
}

.first-tables.table-new tr td.message-check-col {
  background: #e8eeff;
  border-radius: 0;
  line-height: 1.3;
  font-size: 13px;
  padding: 4px 16px;
  margin: 0;
  border-bottom: 1px solid #c7c7c7;
}

.info-icon-tooltip {
  position: relative;
}

.info-icon-tooltip svg {
  width: 20px;
  height: 20px;
}

.info-icon-tooltip:after {
  content: attr(data-tooltip);
  position: absolute;
  top: -56px;
  background: #dbdbdb;
  margin-bottom: 20px;
  width: 370px;
  padding: 1px;
  border-radius: 5px;
  display: none;
  transform: translate(-50%);
  left: 0;
  right: 0;
  margin: auto;
  line-height: 1.4;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  font-size: 12px;
}

.info-icon-tooltip:hover:after {
  display: block;
}

/* Svg animation */

@media screen and (max-width: 1610px) {
  .middle-setting-box {
    width: 100%;
  }

  .main-left-card,
  .rightchart {
    display: block;
  }

  .bottom-settings-box {
    width: 100%;
    margin-top: 11px;
  }

  .leftcards {
    width: 70%;
  }

  .main-div .card .tabs .card-header .nav.nav-pills {
    width: 73%;
  }

  .card .rightchart {
    width: 40%;
  }
}

@media screen and (max-width: 1280px) {
  .main-details-card {
    display: block;
    padding-bottom: 1px;
  }

  .main-left-card,
  .rightchart {
    min-height: 247px;
  }

  .leftcards {
    width: 100%;
  }

  .card ::v-deep .rightchart {
    width: 100%;
  }

  .rightchart .chart-donut {
    width: 40%;
  }

  .leftcards {
    margin-top: 109px;
  }

  .jobSwitchClass {
    width: 68%;
    position: unset;
  }

  .main-div .card .tabs .card-header .nav.nav-pills {
    gap: 20px;
    flex-wrap: wrap;
    justify-content: left;
    width: 100%;
  }

  .top p {
    font-size: 24px;
    margin: 0;
  }

  /* .top {
    display: block;
  } */
  .action-btn {
    margin-top: 0px;
    justify-content: left;
  }

  .main-div.c-enrichment .top {
    padding: 0 20px;
  }

  .main-div.c-enrichment .leftcards {
    padding: 0 20px;
  }

  .bottom-settings-box {
    margin-left: 0;
  }

  table.first-tables.table-new {
    width: 1310px;
  }

  .table-new tr td {
    padding: 10px 20px;
  }

  .middle-setting-box .single-card-box {
    width: 100%;
  }

  .rightchart.skeletonbox .skeleton-loder.skeleton-table-h.roundbox {
    max-width: 350px;
    top: 25px;
    left: 29%;
    right: 50%;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .rightchart .chart-donut {
    margin: auto;
    position: unset;
    width: 34%;
  }

  .upper-setting-box {
    display: block;
  }

  .inner-single-setting {
    min-width: 155px;
    margin-top: 17px;
  }

  .middle-setting-box {
    display: block;
  }

  .middle-setting-box .single-card-box {
    width: 100%;
  }

  .bottom-settings-box {
    width: 100%;
  }

  .jobSwitchClass {
    width: 100%;
  }

  .leftcards {
    margin-top: 29px;
  }

  .main-div .card .tabs .card-header .nav.nav-pills li.nav-item .nav-link {
    padding: 3px 12px;
  }

  .leftcards {
    width: 100%;
    padding: 20px;
  }

  .upper-setting-box {
    padding: 5px 19px 16px;
  }

  .top p {
    font-size: 16px;
  }

  .main-left-card,
  .rightchart {
    min-height: 360px;
  }

  .main-details-card {
    padding-bottom: 0px;
  }
}
</style>
